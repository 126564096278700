import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { PageHeader } from '@design-system/page-header-standard';
import { PageFields, PageHeader as PageHeaderProps, pageHeaderMapper } from 'mappers/pageHeader';

import { Layout } from 'layout/Layout';
import BodyRender from 'components/BodyRender';
import withContentstackPreview from 'utils/livePreview';

import type { ContentPageData } from './models';

const PageHeaderBlock = ({
  page_header,
  fields,
}: {
  page_header: PageHeaderProps;
  fields: PageFields;
}) => {
  if (!page_header) return null;
  if (page_header.visually_hidden)
    return <h1 className="cds-sr-only">{page_header.heading_block.headline}</h1>;

  return <PageHeader {...pageHeaderMapper({ page_header, fields })} />;
};

export const ContentPage: FC<ContentPageData> = ({
  data: {
    contentstackHarpicContentPage,
    contentstackHarpicHeader,
    contentstackHarpicFooter,
    contentstackHarpicSettings,
  },
  pageContext: { uid, locale, title },
}) => {
  if (typeof window === 'undefined') console.info('Building:', { uid, locale, title });
  const {
    seo,
    url,
    body,
    page_header: [page_header],
    fields,
  } = contentstackHarpicContentPage;

  const additionalSchema = function () {
    if (page_header?.variant === 'article') {
      return 'Article';
    }
    if (seo?.canonical_url.href === '/') {
      return 'Organization';
    }

    return null;
  };

  return (
    <Layout
      {...{
        seo,
        header: contentstackHarpicHeader,
        footer: contentstackHarpicFooter,
        pageUid: uid,
        settings: contentstackHarpicSettings,
        schemaImageUrl: seo.open_graph.image?.url,
        additionalSchema: additionalSchema(),
        url,
      }}
    >
      <PageHeaderBlock {...{ page_header, fields }} />

      <BodyRender items={body} currentPageUid={uid} />
    </Layout>
  );
};

export const query = graphql`
  query ContentPage($id: String, $locale: String) {
    contentstackHarpicContentPage(id: { eq: $id }) {
      uid
      url
      fields {
        ...PageFieldsFragment
      }
      page_header {
        ...PageHeaderFragment
      }
      seo {
        ...SeoFragment
      }
      body {
        ...BodyFragment
      }
    }
    contentstackHarpicHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    contentstackHarpicFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    contentstackHarpicSettings {
      ...SettingsFragment
    }
  }
`;

export default withContentstackPreview(ContentPage, 'harpic_content_page');
